<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="1100px"
    top="5vh"
  >
    <div v-loading="loading" class="wrapper">
      <div class="content">
        <div class="tree">
          <el-tree
            :data="treeData"
            :props="defaultProps"
            node-key="id"
            :default-expanded-keys="[1]"
            highlight-current
            style="height: 500px; overflow: auto"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
        <div class="leftTable">
          <el-table
            ref="lTable"
            v-loading="lTableLoading"
            stripe
            :data="lTableData"
            :row-key="getRowKey"
            border
            height="480"
            @selection-change="lTableClick"
          >
            <!-- 选择框 -->
            <el-table-column
              align="center"
              type="selection"
              width="50"
              :reserve-selection="true"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="user_name"
              label="业务员姓名"
              width="auto"
            ></el-table-column>
          </el-table>
          <el-button
            style="float: right; margin-top: 5px"
            type="primary"
            @click="add"
          >
            添加
          </el-button>
        </div>
        <div class="middleImg">
          <i
            class="el-icon-caret-right"
            style="cursor: pointer"
            @click="add"
          ></i>
        </div>
        <div class="rightTable">
          <el-table
            ref="rTable"
            v-loading="rTableLoading"
            stripe
            :data="rTableData"
            border
            height="480"
            @selection-change="rTableClick"
          >
            <el-table-column
              v-if="showbumen"
              align="center"
              prop="depart_name"
              label="部门"
              width="auto"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="user_name"
              label="业务员姓名"
              width="auto"
            ></el-table-column>
            <el-table-column
              v-if="showzhineng"
              align="center"
              prop="depart_name"
              label="职能"
              width="auto"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="mobile"
              label="电话"
              width="auto"
            ></el-table-column>
            <el-table-column align="center" prop="" label="操作" width="60">
              <template #default="{ $index, row }">
                <el-button type="text" @click="handleDelete($index, row)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import {
    getTree,
    getUserData,
    getRightStaff,
    saveStaff,
    defaultStaff,
    defaultStaffT,
    getRightStaffT,
    saveStaffT,
  } from '@/api/setPrice'
  import { postAction } from '@/api/Employee'

  export default {
    name: 'SetStaff',
    components: {},
    props: {
      showbumen: {
        type: Boolean,
        default: () => true,
      },
      showzhineng: {
        type: Boolean,
        default: () => false,
      },
      id: {
        type: Number,
        default: () => 0,
      },
      staffids: {
        type: String,
        default: () => '',
      },
      zidingyi: {
        type: Boolean,
        default: () => true,
      },
      rtd: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: () => '指定部门员工',
      },
      renwuguanli: {
        type: Boolean,
        default: () => false,
      },
      isSub: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        isTrans: '', // 是否调拨价用 1是 ‘’否
        id2: 0,
        showDialog: false,
        loading: false,
        lTableLoading: false,
        rTableLoading: false,
        tableRowSelect: [],
        treeData: [],
        lTableData: [],
        rTableData: [],
        defaultProps: {
          children: 'children',
          label: 'depart_name',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          console.log(this.id)
          this.getRtableData()
        } else {
          this.lTableData = []
          this.rTableData = []
          // 清空选中
          this.$refs.lTable.clearSelection()
          this.$emit('refresh')
        }
      },
    },
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      // 获取默认右边表格数据
      async getRtableData() {
        if (this.staffids == '') {
          if (this.rTableData.length == 0) {
            if (this.id == 0) return false
            this.rTableLoading = true
            let { data } =
              this.isTrans == 1
                ? await getRightStaffT({
                    id: this.id,
                    pageNo: 1,
                    pageSize: 1000,
                  })
                : await getRightStaff({ cust_id: this.id })
            console.log(data)
            this.rTableData = data.list
            this.rTableLoading = false
            this.loading = false
          }
        } else {
          if (!this.renwuguanli) {
            console.log('ids', this.staffids)
            if (this.isTrans == 1) {
              defaultStaff({ limit_user: this.staffids }).then((res) => {
                this.rTableData = res.data
              })
            } else {
              defaultStaffT({ limit_user: this.staffids }).then((res) => {
                this.rTableData = res.data.list
              })
            }
          } else {
            postAction('/baseAdmin/common/user-list', {
              limit_user: this.staffids,
            }).then((res) => {
              this.rTableData = res.data
            })
          }
        }
      },
      // 树 数据获取
      async fetchData() {
        let { data, msg, code } = await getTree({ scheme_id: this.id })
        console.log(data)
        if (code == 200) {
          this.treeData = data.depart
          this.rTableData = data.list
        } else {
          this.$message.error(msg)
        }
      },
      // 树形节点点击事件
      handleNodeClick(val) {
        this.getlTable(val.id)
      },
      // 左右表格 row 点击事件
      lTableClick(val) {
        console.log(val)
        this.tableRowSelect = val
      },
      rTableClick(val) {
        console.log(val)
      },
      add() {
        let select = this.tableRowSelect
        console.log(select)
        if (select.length == 0) {
          this.$message.error('请选择业务员')
          return false
        }
        select.forEach((item) => {
          // 先判断存不存在相同
          let haveed = this.rTableData.filter(
            (rTdItem) => rTdItem.id == item.id
          )
          console.log(haveed)
          if (haveed.length !== 0) {
            try {
              haveed.forEach((item1) => {
                // this.$message.error('业务员 ' + item1.user_name + ' 已存在')
                // throw new Error('存在相同业务员')
                return false
              })
            } catch (e) {
              if (e.message == '存在相同业务员') {
                throw e
              }
            }
          } else {
            this.rTableData.push(item)
          }
        })
        this.$message.success('添加成功')
      },
      // 左边表格数据获取
      async getlTable(id) {
        this.lTableLoading = true
        let { data, msg, code } = await getUserData({ depart_id: id })
        console.log(data, code)
        if (code == 200) {
          this.lTableData = data
        } else {
          this.$message.error(msg)
        }
        this.lTableLoading = false
      },
      // 删除
      handleDelete(index, row) {
        console.log('删除', index)
        this.rTableData.splice(index, 1)
        this.$refs.lTable.toggleRowSelection(row, false)
      },
      getRowKey(row) {
        return row.id
      },
      save() {
        let data = this.rTableData
        let userArr = []
        data.forEach((item) => {
          if (item.id) {
            userArr.push(item.id)
          } else if (item.user_id) {
            userArr.push(item.user_id)
          }
        })
        let endData =
          JSON.stringify(userArr) == '[]' ? '' : JSON.stringify(userArr)
        console.log(endData)
        if (this.isSub) {
          this.$emit('submit', { id: this.id2, user_ids: userArr })
          return false
        }
        if (this.zidingyi) {
          if (this.isTrans == 1) {
            saveStaffT({ id: this.id, user_ids: endData }).then((res) => {
              this.$emit('refresh')
              this.showDialog = false
              this.$message.success('保存成功')
            })
          } else {
            saveStaff({ cust_id: this.id, user_data: endData }).then((res) => {
              this.$emit('refresh')
              this.showDialog = false
              this.$message.success('保存成功')
            })
          }
        } else {
          let d = JSON.parse(JSON.stringify(data))
          d.forEach((item) => {
            if (!item.user_id) {
              this.$set(item, 'user_id', item.id)
            }
          })
          this.$emit('rtd', d)
          this.showDialog = false
        }
      },

      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    min-height: 500px;
  }
  .content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .tree {
    width: 25%;
  }
  .leftTable {
    box-sizing: border-box;
    width: 25%;
    padding: 0 10px;
  }
  .middleImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 5%;
    text-align: center;
    i {
      width: 100%;
      font-size: 50px;
      color: #000;
    }
  }
  .rightTable {
    width: 45%;
    padding: 0 10px;
  }
</style>
